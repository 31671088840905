<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách chấm công</h3>
      </div>
      <div class="card-title">
        <button
            class="font-weight-bold font-size-3 mr-2 btn btn-primary"
            @click="openModalImport"
        >
          <i class="bi bi-upload"></i>
          Tải lên
        </button>
        <div class="dropdown dropdown-inline mr-2">
          <button type="button" @click="exportTimeKeeping" :disabled="is_disable_export"
                  class="btn btn-light-primary font-weight-bolder"
                  :class="is_disable_export ? 'spinner spinner-white spinner-left' : ''">
												<span class="svg-icon svg-icon-md">
													<svg xmlns="http://www.w3.org/2000/svg"
                               width="24px"
                               height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" stroke-width="1" fill="none"
                               fill-rule="evenodd">
															<rect x="0" y="0" width="24" height="24"></rect>
															<path
                                  d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                                  fill="#000000" opacity="0.3"></path>
															<path
                                  d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                                  fill="#000000"></path>
														</g>
													</svg>
												</span>Xuất excel
          </button>
        </div>
        <button
            class="font-weight-bold font-size-3  btn btn-success"
            @click="createOrDetailTimeKeeping()"
        >Thêm mới
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row align-items-center">
          <div class="col-md-3 col-sm-12">
            <div class="form-group">
              <label>Tìm kiếm
                <span class="text-danger"></span></label>
              <el-input placeholder="Mã nhân viên, tên, mã theo máy, tên máy chấm công"
                        v-model="query.user_id"></el-input>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Từ ngày - Đến ngày
                <span class="text-danger"></span></label>
              <date-picker
                  placeholder="Chọn thời gian"
                  format="DD-MM-YYYY"
                  range
                  :clearable="false"
                  valueType="YYYY-MM-DD" v-model="query.date_range">
              </date-picker>
            </div>
          </div>
          <div class="col-md-2 col-sm-12">
            <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                    @click="searchData">
              <i class="fa fa-search"></i> Tìm kiếm
            </button>
            <button v-else type="button" disabled
                    class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
              Tìm kiếm
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="example-preview table-responsive">
              <table class="table table-vertical-center">
                <thead>
                <tr>
                  <th scope="col">STT</th>
                  <th scope="col" style="width: 15%">Tên</th>
                  <th scope="col" style="width: 15%">Mã nhân viên</th>
                  <th scope="col" style="width: 15%">Tên máy chấm công</th>
                  <th scope="col" style="width: 15%">Mã theo máy chấm công</th>
                  <th scope="col" style="width: 15%">Ngày</th>
                  <th scope="col" style="width: 15%">Giờ đến</th>
                  <th scope="col" style="width: 15%">Giờ về</th>
                  <th scope="col" style="width: 15%" class="min-w-200px">Hành động</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in data.data" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.fuser && item.fuser.user ? item.fuser.user.name : "" }}</td>
                  <td>{{ item.fuser ? item.fuser.user_id + 100000 : '' }}</td>
                  <td>{{ item.fuser ? item.fuser.name : '' }}</td>
                  <td>{{ item.fuser ? item.fuser.fingerprint_machine_id : '' }}</td>
                  <td>{{ moment(item?.date_format).format("DD-MM-YYYY") }}</td>
                  <td>{{ item?.in }}</td>
                  <td>{{ item?.out }}</td>
                  <td>
                    <button title="Xem chi tiết" @click="createOrDetailTimeKeeping(item)" href="javascript:"
                            class="btn btn-icon ml-2 btn-outline-info"><i class="far fa-eye"></i></button>
                    <!-- <router-link :to="{ name: 'user-log-histories', params: {id : item.id} }"
                   title="Xem Lịch sử đăng nhập"
                   class="btn btn-icon ml-2 btn-outline-info"><i class="far fa-eye"></i>
      </router-link> -->
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="edu-paginate mx-auto text-center" style="display: flex;justify-content: center">
              <paginate
                  :page-count="last_page"
                  :page-range="3"
                  :margin-pages="1"
                  :click-handler="clickCallback"
                  :prev-text="'Trước'"
                  :next-text="'Sau'"
                  :container-class="'pagination b-pagination'"
                  :pageLinkClass="'page-link'"
                  :next-link-class="'next-link-item'"
                  :prev-link-class="'prev-link-item'"
                  :prev-class="'page-link'"
                  :next-class="'page-link'"
                  :page-class="'page-item'"
              >
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog @close="resetModal" :title="title" class="responsive" :visible.sync="dialogFormVisible">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(createTimeKeepingSubmit)">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Nhân viên
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="doi_tuong_phan_hoi" name="Nhân viên"
                                    :rules="{ required: true }"
                                    v-slot="{ errors,classes }">
                  <el-select filterable class="w-100" placeholder="Nhân viên"
                             v-model="form.user_id"
                             clearable
                  >
                    <el-option
                        v-for="item in userMappings"
                        :key="item.fingerprint_user_id"
                        :label="(item.user_id + 100000 ) + ' - ' + item.user_name + ' - ' + item.fingerprint_machine_id"
                        :value="item.fingerprint_user_id"
                    >
                      <span style="float: left">{{
                          (item.user_id + 100000) + " - " + item.user_name + " - " + item.fingerprint_machine_id
                        }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Thời gian
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="date_time" name="Thời gian" rules="required" v-slot="{ errors,classes }">
                  <el-date-picker class="w-100" ref="date_time" name="start_date"
                                  placeholder="Chọn thời gian chấm công" v-model="form.date_time"
                                  format="dd-MM-yyyy HH:mm:ss"
                                  value-format="yyyy-MM-dd HH:mm:ss"
                                  type="datetime"
                                  :class="classes"
                                  :picker-options="pickerOptions"
                  ></el-date-picker>
                  <!-- <span class="form-text text-muted" v-if="companyEvent.checked_success">Không được sửa khi đã có người check-in.</span> -->
                  <div class="fv-plugins-message-container">
                    <div data-field="date_time" data-validator="notEmpty"
                         class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <footer class="modal-footer pt-5 pb-0">
            <button type="button" class="btn btn-secondary" @click="resetModal">Đóng</button>
            <button type="submit" class="btn btn-success">Thêm mới</button>
          </footer>
        </form>
      </ValidationObserver>
    </el-dialog>
    <el-dialog @close="detail.dialogViewVisible = false" :title="detail.title" class="responsive"
               :visible.sync="detail.dialogViewVisible">
      <div v-loading="detail.isLoading" class="row">
        <div class="col-sm-12">
          <div class="example-preview table-responsive">
            <table class="table table-vertical-center">
              <thead>
              <tr>
                <th scope="col" style="width: 20%">STT</th>
                <th scope="col" style="width: 50%">Giờ chấm</th>
                <th scope="col" style="width: 40%">Hành động</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in detail.data" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ moment(item?.date_time).format('DD-MM-YYYY HH:mm:ss') }}</td>
                <td>
                  <button :disabled="item.is_auto == 0 ? true : false"
                          :title="item.check_delete == 1 ? 'Xóa' : 'Không thể xóa'"
                          @click="deleteTimeKeeping(item.id,item.date_time,item.fingerprint_user_id, item.user_id)" href="javascript:"
                          class="btn btn-icon btn-outline-danger"><i class="fas fa-trash"></i></button>
                  <!-- - -->
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <footer class="modal-footer pt-5 pb-0">
        <button type="button" class="btn btn-secondary" @click="detail.dialogViewVisible = false">Đóng</button>
      </footer>
    </el-dialog>
    <el-dialog title="Import danh sách chấm công" :visible.sync="isImport" :close="closeModalImport">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent v-if="showTable==false">
          <div>
            <div class="row import">
              <div class="col-md-12">
                <p>Bước 1: Tải file mẫu</p>
                <button type="button" class="btn btn-info btn-sm" @click="downloadFile">
                  <i class="bi bi-download"></i>
                  Tải file mẫu
                </button>
              </div>
              <div class="col-md-12 mt-4">
                <p>Bước 2: Tải file excel lên hệ thống</p>
                <div class="file-input">
                  <ValidationProvider
                    name="type_task"
                    rules="required|max:30" v-slot="{ errors,classes }"
                    vid="type_task"
                  >
                    <input
                        ref="fileExam"
                        type="file"
                        name="file-input-exam"
                        id="file-input-exam"
                        class="file-input__input"
                        @change="getDataFile"
                        hidden
                        :disabled="disableImport"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </ValidationProvider>
                  <label class="btn" for="file-input-exam"
                         :class="{'btn-secondary' : disableImport, 'btn-success': !disableImport}">
                    <span><i class="bi bi-upload mr-2"></i>Tải lên</span>
                  </label>
                  <div v-if="fileDataImport">
                    <p class="badge badge-secondary p-2 position-relative mt-3">
                        {{ fileDataImport.name }}
                        <i class="bi bi-x-circle-fill clear-file" style="font-size: 13px; cursor: pointer"
                          @click="deleteFileExcel()">
                        </i>
                    </p>
                  </div>
                  <div v-else class="text-danger">
                    Vui lòng chọn file
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex d-flex flex-row-reverse">
            <button type="button" class="btn btn-success ml-4" @click="ImportExcel" :disabled=disableSave>
              Xác nhận
            </button>
            <button type="button" class=" btn btn-secondary" @click="closeModalImport">Hủy</button>
          </div>
        </form>
        <div v-else>
          <p>Hệ thống phát hiện có lỗi trong quá trình import dữ liệu:</p>
          <div class="mt-5 table-responsive">
            <table class="table table-bordered  table-vertical-center ">
              <thead>
              <tr class="" style="background-color: #F8F8F8 ; font-size: 13px">
                <th>STT</th>
                <th>Bản ghi</th>
                <th>Nội dung lỗi</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in recordErrors" :key="item?.id">
                <td>{{ index + 1 }}</td>
                <td>
                  <ul class="d-flex flex-column">
                    <span>Mã nhân sự: {{ item.ma_nhan_su }}</span>
                    <span>Ngày: {{ item.date }}</span>
                    <span>Giờ: {{ item.time }}</span>
                  </ul>  
                </td>
                <td>
                  <p class="text-danger" v-for="error in item.errors" :key="error.id">
                    {{ error }}
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary" @click="comeback()">Quay lại</button>
          </div>
        </div>
      </ValidationObserver>
    </el-dialog>
  </div>
</template>

<script>
import {
	DELETE_TIMEKEEPING,
	GET_TIMEKEEPING_LIST,
	EXPORT_TIMEKEEPING,
	STORE_TIMEKEEPING,
	DETAIL_TIMEKEEPING,
	GET_USER_MAPPING,
	IMPORT_TIMEKEEPING, PREVIEW_TIMEKEEPING, DETAIL_TIMEKEEPING_FROM_USER
} from "@/core/services/store/department/department.module";
import Swal from "sweetalert2";
import {mapGetters} from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {utils, read} from "xlsx";
import {STAFF_CODE, DATE, TIME} from "@/core/option/constantExcel";
import {exportExcel} from "@/core/filters";

//   import DepartmentStatus from "./DepartmentStatus";

export default {
  name: "TimeKeepingList",
  components: {
    DatePicker
  },
  data() {
    return {
      moment: moment,
      data: [],
      last_page: 1,
      query: {
        name: '',
        status: '',
        date_range: [],
        user_id: ''
      },
      form: {
        user_id: '',
        date_time: '',
      },
      is_disable_search: false,
      page: 1,
      detail: {
        isLoading: false,
        data: [],
        dialogViewVisible: false,
        title: ""
      },
      dialogFormVisible: false,
      is_edit: false,
      is_disable_export: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      userMappings: [],
      search_loading: false,
      recordErrors: [],
      showTable: false,
      isImport: false,
      fileDataImport: null,
      importData: null,
      disableImport: false,
      disableSave : true
    }
  },
  mounted() {
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách chấm công", route: 'timekeeping/'}
    ]);
    this.getDateFilterDefault();
    this.fetchData();
    this.getUserMapping();
  },
  computed: {
    ...mapGetters(["currentUser"]),
    title() {
      return this.is_edit ? 'Sửa phòng ban' : 'Tạo chấm công';
    },
  },
  methods: {
    getUserMapping() {
      this.$store.dispatch(GET_USER_MAPPING)
          .then(res => {
            this.userMappings = res.data;
          }).finally(() => {
      })
    },
    getDateFilterDefault() {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const nowPicker = moment().format('YYYY-MM-DD');
      let date_filter_default = [];
      date_filter_default.push(startOfMonth);
      date_filter_default.push(nowPicker);
      this.query.date_range = date_filter_default;
    },
    formatTime(time) {
      if(time < 10){
        return `0${time}`;
      }
      return time;
    },
    ExcelHourToJSHour(serial) {
      let baseNumber = serial - Math.floor(serial) + 0.0000001;

      let totalSeconds = Math.floor(86400 * baseNumber);
      let seconds = totalSeconds % 60
      totalSeconds -= seconds
      let hours = Math.floor(totalSeconds / (60 * 60));
      let minutes = Math.floor(totalSeconds / 60) % 60;

      return `${hours}:${this.formatTime(minutes)}:${this.formatTime(seconds)}`
    },
    ExcelDateToJSDate(serial) {
      const utc_days  = Math.floor(serial - 25569);
      const utc_value = utc_days * 86400;
      const date_info = new Date(utc_value * 1000);

      return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate());
    },
    exportTimeKeeping() {
      let payload = {
        from: this.query.date_range[0],
        to: this.query.date_range[1]
      }
      this.is_disable_export = true;
      this.$store.dispatch(EXPORT_TIMEKEEPING, payload).then((data)=>{
        const HEADER_ROW_EXPORT_TIME_KEEP= [
          {
            key: 'stt',
            value: 'STT',
          },
          {
            key: 'name',
            value: 'Mã nhân sự',
          },
          {
            key: 'user_id',
            value: 'Họ và tên ',
          },
          {
            key: 'date_format',
            value: 'Văn phòng',
          },
          {
            key: 'day_of_week',
            value: 'Thứ',
          },
          {
            key: 'in',
            value: 'Giờ đến',
          },
          {
            key: 'out',
            value: 'Giờ về',
          }
        ]
        exportExcel(HEADER_ROW_EXPORT_TIME_KEEP,data.data, `Danh sách chấm công từ ${moment(payload.from).format('DD-MM-YYYY')} đến ${moment(payload.to).format('DD-MM-YYYY')}.xlsx`, true)
      }).finally((data) => {
        this.is_disable_export = false;
      });
    },
    resetModal() {
      this.dialogFormVisible = false;
      this.form.user_id = '';
      this.form.date_time = '';
    },
    createTimeKeepingSubmit() {
      this.$store.dispatch(STORE_TIMEKEEPING, {
        fingerprint_user_id: this.form.user_id,
        date_time: this.form.date_time
      }).then(res => {
        this.$notify({
          title: 'Thành công',
          message: 'Thêm dữ liệu thành công',
          type: 'success'
        });
        this.fetchData();
        this.dialogFormVisible = false;
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        } else {
          this.$notify({
            title: 'Thất bại',
            message: e.data.message,
            type: 'success'
          });
        }
      })
    },
    createOrDetailTimeKeeping(fingerprint_user) {
      if (fingerprint_user) {
        let date = fingerprint_user.date_format;
        let name = fingerprint_user?.fuser?.user?.name;
				let user_id = fingerprint_user.user_id;
        this.detailSpecificDate(user_id, date);
        this.detail.dialogViewVisible = true;
        this.detail.title = `Chi tiết chấm công ${name} (${moment(date).format("DD-MM-YYYY")})`
      } else {
        this.is_edit = false;
        this.dialogFormVisible = true;
      }
    },
    detailSpecificDate(user_id, date) {
      this.detail.isLoading = true;
      let payload = {
        from: date,
        user_id: user_id
      }
      this.$store.dispatch(DETAIL_TIMEKEEPING_FROM_USER, payload)
          .then(res => {
            this.detail.data = res
          }).finally(() => {
        this.detail.isLoading = false;
      })
    },
    deleteTimeKeeping(id, date, finger_id, user_id) {
      let date_format = moment(date).format('YYYY-MM-DD');
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa thông tin?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_TIMEKEEPING, id).then(() => {
            this.detailSpecificDate(user_id, date_format);
            this.fetchData();
            this.$notify({
              title: 'Thành công',
              message: 'Xóa dữ liệu thành công',
              type: 'success'
            });
          })
        }
      });
    },
    searchData() {
      this.page = 1;
      this.fetchData();
    },
    fetchData() {
      this.is_disable_search = true;
      let payload = {
        page: this.page,
        search: this.query.user_id,
        from: this.query.date_range[0],
        to: this.query.date_range[1]
      }
      if (this.query.status !== '') {
        payload.status = this.query.status;
      }
      this.$store.dispatch(GET_TIMEKEEPING_LIST, payload)
          .then(res => {
            this.data = res.data;
            this.last_page = res.data.last_page
          }).finally(() => {
        this.is_disable_search = false;
      })
    },
    clickCallback(obj) {
      this.page = obj;
      this.fetchData();
    },
    downloadFile() {
      const assetURL = process.env.BASE_URL + 'excel/File mẫu import chấm công.xlsx';
      const link = document.createElement('a');
      link.href = assetURL;
      link.target = '_blank';
      link.download = 'File mẫu import chấm công.xlsx';
      link.click();
    },
    comeback() {
      this.showTable = false
      this.disableSave = false
    },
    deleteFileExcel() {
      this.disableImport = false
      this.fileDataImport = null
      this.$refs.fileExam.value = null;
    },
    openModalImport() {
      this.isImport = true
    },
    async getDataFile(e) {
      if (e.target.files.length) {
        this.disableImport = true
        this.fileDataImport = e?.target?.files[0]
        this.importData = await this.fileToJson(this.fileDataImport);
        this.disableSave = false
      }
    },
    closeModalImport() {
      this.isImport = false
      this.fileDataImport = null
      this.disableImport = false
      this.$refs.fileExam.value = null;
      this.disableSave = true
      this.showTable = false
    },
    checkTimeFormat(inputTime) {
      const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/
      return isValid.test(inputTime);
    },
    checkDateFormat(inputDate) {
      const datePattern = /^(\d{4})\-(\d{1,2})\-(\d{1,2})$/;
      return datePattern.test(inputDate);
    },
    mapDataToError(item) { 
      let listErrors = [];
        if(!item.time) {
          listErrors.push('Thiếu giờ chấm công')
        } 
        if(!item.date) {
          listErrors.push('Thiếu ngày chấm công')
        } 
        if(item.time && !this.checkTimeFormat(item.time)) {
          listErrors.push('Giờ sai định dạng hh:mm:ss')
        } 
        if(item.date && !this.checkDateFormat(item.date)) {
          listErrors.push('Ngày sai định dạng yyyy-mm-dd')
        }
        return {...item, errors: listErrors};
    },
    ImportExcel() {
      if (this.importData == null) {
        return
      }
      let payload = this.importData.map((data) => {
        return {
          ma_nhan_su: data?.staff_code?.toString() ?? '0',
          date_time: data?.date?.toString() + ' ' + data?.time?.toString(),
          date: data?.date,
          time: data?.time,
        }
      })
      this.$store.dispatch(PREVIEW_TIMEKEEPING, payload).then(data => {
        const errors = data.data.data.filter(recordError=>recordError.errors.length > 0)
        this.recordErrors= this.importData.map((item) => this.mapDataToError(item)).filter(recordError=>recordError.errors.length > 0).concat(errors);
        this.showTable = this.recordErrors.length > 0;
        this.disableSave = true
        if(this.recordErrors.length === 0) {
          this.$store.dispatch(IMPORT_TIMEKEEPING, payload).then((data) => {
            this.$emit("getExam")
            this.$notify({
              title: 'Thành công',
              message: 'Tải lên bảng chấm công thành công!',
              type: 'success'
            });
            this.isImport = false
            this.closeModalImport()
          }).catch(()=>{
            this.$notify({
              title: 'Lỗi',
              message: 'Đã có lỗi xảy ra!',
              type: 'error'
            });
          }).finally(()=>{
            this.disableSave = false
          })
        }
      })
    },
    fileToJson(file) {
      return new Promise((res, rej) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          const bstr = e.target.result
          const wb = read(bstr, {type: 'binary'})
          const bangchamcong = wb.SheetNames[0]
          const chamcongSheet = wb.Sheets[bangchamcong]
          const listChamcong = utils.sheet_to_json(chamcongSheet)
          let overrideField = []
          overrideField = listChamcong.map((item, index) => {
            return {
              id: index,
              staff_code: item[STAFF_CODE] ? item[STAFF_CODE] : undefined ,
              date: typeof item[DATE] === "number" ? moment(this.ExcelDateToJSDate(item[DATE])).format('YYYY-MM-DD') : item[DATE],
              time: typeof item[TIME] === "number" ? this.ExcelHourToJSHour(item[TIME]) : item[TIME],
            }
          })
          res(overrideField)
        }
        reader.onerror = (e) => {
          rej(e);
        };
        reader.readAsBinaryString(file)
      });
    }
  }
}
</script>

<style>
.checkbox-slide, .checkbox-switch {
  border-radius: 20px !important;

}

.clear-file {
  position: absolute;
  color: red;
  top: -5px;
  right: -5px;
}

.swal2-container {
  z-index: 3000 !important;
}

@media (max-width: 430.5px) {
  .el-dialog__header {
    max-width: 306px;
  }
}

@media (max-width: 768.5px) {
  .el-dialog__header {
    max-width: 340px;
  }
}
</style>
